<template>
  <div class="SalarieModifyPassword" >

    <CModal
      :show.sync="passwordReseted"
      :no-close-on-backdrop="true"
      title="Modification de votre mot de passe"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre mot de passe a bien été modifié 👍
      </p>
      <template #footer>
        <CButton @click="passwordReseted = false" color="primary">Fermer</CButton>
      </template>
    </CModal>
    
    <CRow>
      <CCol>
        <h1>Réinitialiser votre mot de passe </h1>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardBody>
        <CForm v-on:submit.prevent="modifyPassword()">
          <CInput
            label="Votre mot de passe actuel"
            type="password"
            maxlength="200"
            v-model="oldPassword"
          >
          <template #description>
            <small v-if="errorOldPasswordMessage" class="text-danger">Ce mot de passe ne correspond pas à votre mot de passe actuel, veuillez réessayer</small>
          </template>
          </CInput>


          <CInput
            label="Votre nouveau mot de passe"
            type="password"
            maxlength="249"
            v-model="newPassword"
            @input="$v.newPassword.$touch()"
            :isValid="$v.newPassword.$dirty ? !$v.newPassword.$error : null"
            invalid-feedback="Votre mot de passe doit contenir au moins 8 caractères, dont une majuscule, un chiffre et un caractère spécial."
          >
          </CInput>

          <CInput
            label="Confirmation de votre nouveau mot de passe"
            type="password"
            maxlength="249"
            v-model="newPasswordConfirmation"
            @input="$v.newPasswordConfirmation.$touch()"
            :isValid="$v.newPasswordConfirmation.$dirty ? !$v.newPasswordConfirmation.$error : null"
            invalid-feedback="Veuillez rentrer deux mots de passe identiques"
          >
          </CInput>

          <CRow class="mt-4">
            <CCol class="text-center">
              <CButton
                type="submit" :color="modifyPasswordButtonStyle"
                shape="pill" block class="px-4"
                :disabled="$v.oldPassword.$invalid || $v.newPasswordConfirmation.$invalid || modifyPasswordInProcess">
                {{ modifyPasswordButtonText }}</CButton>
            </CCol>
          </CRow>

        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, sameAs } from 'vuelidate/lib/validators'
import { password_validation } from '@/validators/validators'

import { APIUserConnected } from '@/api/APIUserConnected'

const apiUserConnectedService = new APIUserConnected()


export default {
  name: 'modifyPassword',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      oldPassword: '',
      errorOldPasswordMessage: false,
      newPassword: '',
      newPasswordConfirmation: '',

      modifyPasswordButtonText: 'Réinitialiser votre mot de passe',
      modifyPasswordButtonStyle: 'outline-primary',
      modifyPasswordInProcess: false,

      passwordReseted: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
  },
  validations: {
    oldPassword: {
      required,
    },
    newPassword: {
      maxLength: maxLength(249),
      required,
      password_validation
    },
    newPasswordConfirmation: {
      maxLength: maxLength(249),
      required,
      sameAsPassword: sameAs('newPassword'),
    }
  },
  methods: {
    modifyPassword () {
      this.errorOldPasswordMessage = false
      this.modifyPasswordButtonText = 'Modification en cours'
      this.modifyPasswordButtonStyle = 'secondary'
      this.modifyPasswordInProcess = true
      apiUserConnectedService.resetPassword(this.token, this.oldPassword, this.newPassword, this.newPasswordConfirmation)
      .then(() => {
          this.passwordReseted = true
        }, (error) => {
          if (error.response && error.response.data.old_password && error.response.data.old_password === "wrong_password") {
            this.errorOldPasswordMessage = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        })
      .finally(() => {
        this.modifyPasswordButtonText = 'Réinitialiser votre mot de passe'
        this.modifyPasswordButtonStyle = 'outline-primary'
        this.modifyPasswordInProcess = false
      })
    },

    goHome () {
      this.$router.push('/')
    }
  }
}
</script>
